import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subject } from 'rxjs';
import { SIGN_UP_URL } from '../../app.models';

@Component({
  selector: 'temptik-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingComponent {
  public hasPromo = false;
  public defaultPrice = 8;
  public calculatedPrice$ = new Subject<number>();
  public discount: number;
  public saving: number;
  public discountType: 'none' | 'startup' | 'volume' = 'none';

  private updateTimeoutRef;

  constructor(private gaService: GoogleAnalyticsService) {}

  public onUsersInputChange(count: number): void {
    clearTimeout(this.updateTimeoutRef);
    this.updateTimeoutRef = setTimeout(() => this.calc(count), 400);
  }

  public onSignUpClick(): void {
    window.open(SIGN_UP_URL, '_blank');
    this.gaService.event('signup_click', 'pricing', 'Sign up click');
  }

  private calc(count: number): void {
    this.discount = 0;
    this.discountType = 'none';

    if (count <= 5) {
      this.discount = 20;
      this.discountType = 'startup';
    } else if (count > 30 && count <= 50) {
      this.discount = 15;
      this.discountType = 'volume';
    } else if (count > 50) {
      this.discount = 20;
      this.discountType = 'volume';
    }

    if (this.discount !== 0) {
      const calculatedPrice = Math.round((this.defaultPrice - (this.defaultPrice / 100) * this.discount) * 100) / 100;
      this.calculatedPrice$.next(calculatedPrice);
      this.saving = count * this.defaultPrice - count * calculatedPrice;
    } else {
      this.calculatedPrice$.next(this.defaultPrice);
    }

    this.gaService.event('enter_userCount', 'pricing', 'Entering user count', count);
  }
}
