<section>
  <h2>Warum temptik?</h2>
  <h3 class="sub-title">
    Dank temptik sparst du Zeit und Geld. In der App hast du deine Ausgaben und Aufwände - von jedem deiner Projekte -
    jederzeit und vollständig im Blick.
  </h3>

  <div class="grid">
    <img src="assets/paper-list.svg" alt="illustration, Mann sucht auf einer langen Papier Liste nach einträgen" />
    <div>
      <p>
        <b>Überblick jederzeit</b> - Eigentlich willst du nur überprüfen, ob dein aktuelles Projekt im grünen Bereich
        ist oder ob schon wieder zu viel Zeit für eine Problemlösung draufgegangen ist. Doch wo sind bloss all die
        Stundenzettel und Quittungen der Mitarbeiter hin?
      </p>
      <p>
        <b>Vergiss Zeitverluste</b> - Selbst wenn du bereits nicht mehr auf die Papierform setzt und Excel verwendest,
        wie viel Zeit geht wohl dafür drauf, das Excel aktuell zu halten? Zum Beispiel, um alle Stunden und Ausgaben von
        allen Mitarbeitern einzutragen? Hast du den Aufwand schon einmal zusammengerechnet?
      </p>
      <p>
        <b>Simple Handhabung</b> - Mit der temptik App kann jeder Mitarbeiter seine Zeit gleich nach getaner Arbeit
        erfassen. Den Spesenzettel muss er auch nicht aufbewahren, denn er kann die Quittung mit dem Smartphone
        fotografieren und hochladen.
      </p>
      <p>
        <b>Auswertungen mit einem Klick</b> - Mit temptik brauchst du nur noch die Auswertung deiner Kostenstelle
        anzuklicken, schon kennst du Aufwand und Ausgaben!
      </p>
    </div>
  </div>
</section>
