<section>
  <div class="grid">
    <div class="cell">
      <div class="tt-logo">
        <img class="tt-icon" src="assets/tt-icon.svg" alt="temptik logo" />
        <h2>temptik</h2>
      </div>
      <h3>
        Die allergrösste Verschwendung ist die Zeitverschwendung. <br />
        Dank temptik kannst du deine Ressourcen gezielt einsetzen!
      </h3>
    </div>

    <div class="cell">
      <h3>
        Kontakt
      </h3>
      <p>
        info@temptik.com <br />
        +41 32 625 31 31
      </p>
      <!-- <p>
        iJobs AG <br />
        Bergstrasse 3 <br />
        CH - 4513 Langendorf
      </p> -->

      <h3>
        Hilfe-Center
      </h3>
      <p>
        <a routerLink="faq">FAQ</a><br />
        support@temptik.com
      </p>
    </div>
  </div>

  <footer>
    <button mat-button class="mat-small" routerLink="privacy">Datenschutzerklärung</button>
    <button mat-button class="mat-small" routerLink="terms">AGB</button>
    <button mat-button class="mat-small" routerLink="imprint">Impressum</button>
  </footer>
</section>
