<section>
  <h2>Funktionen</h2>
  <h3 class="sub-title">
    Wir haben Grosses vor. Laufend werden neue Funktionen hinzukommen, die temptik noch besser machen.
  </h3>

  <div class="cards">
    <temptik-feature-card class="feature-card">
      <img
        src="assets/time-recording.svg"
        alt="illustration, Mann sitz da und gibt seine Arbeitszeit im Laptop ein"
        illustration
      />
      <h4 header>Arbeitszeiterfassung</h4>
      <div description>
        <p>
          Egal wo du dich gerade befindest. Nach erledigter Arbeit: Deine temptik App öffnen, Kostenstelle wählen und
          Zeit eintragen. Fertig!
        </p>
      </div>
    </temptik-feature-card>

    <temptik-feature-card class="feature-card">
      <img
        src="assets/triangle-releation.svg"
        alt="illustration, drei Menschen in einem Dreieck vernetzt durch ihre Geräte"
        illustration
      />
      <h4 header>Die Dreiecksbeziehung</h4>
      <div description>
        <p>
          Diese Funktion ist für Temporärbüros gedacht. Dein Kandidat erfasst seine Zeit, dein Kunde prüft und du
          rechnest ab!
        </p>
      </div>
      <div hint>Auftragserfassung vorerst nur via REST-Schnittstelle möglich!</div>
    </temptik-feature-card>

    <temptik-feature-card class="feature-card">
      <img
        src="assets/receipt.svg"
        alt="illustration, Frau schaut eine fotografierte Quittung auf ihrem Smartphone an"
        illustration
      />
      <h4 header>Spesenerfassung</h4>
      <div description>
        <p>
          Vergiss das Aufbewahren und Suchen von Spesenquittungen. Dank temptik kannst du diese fotografieren und ganz
          einfach hochladen.
        </p>
      </div>
    </temptik-feature-card>

    <temptik-feature-card class="feature-card">
      <img src="assets/cost-centres.svg" alt="illustration, Standorte der Kostenstellen" illustration />
      <h4 header>Deine Kostenstellen</h4>
      <div description>
        <p>Behalte Aufwand und Ausgaben deiner Kostenstellen jederzeit im Blick.</p>
      </div>
    </temptik-feature-card>

    <temptik-feature-card class="feature-card">
      <img src="assets/holiday.svg" alt="illustration, Frau zeichnet Ferientage im Kalender ein" illustration />
      <h4 header>Abwesenheitsverwaltung</h4>
      <div description>
        <p>
          Wie viel Ferienguthaben hast du noch für dieses Jahr? In der temptik App kannst du ganz einfach nachsehen.
        </p>
      </div>
    </temptik-feature-card>

    <temptik-feature-card class="feature-card">
      <img src="assets/reports.svg" alt="illustration, Mann zeigt Statistiken an einer Tafel" illustration />
      <h4 header>Auswertungen</h4>
      <div description>
        <p>Wir bieten eine Vielzahl von Auswertungen an. Auch mit Excel-Export für weitere Analysen.</p>
      </div>
    </temptik-feature-card>
  </div>
</section>
