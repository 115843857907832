import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_URL } from '../app.models';

@Component({
  selector: 'temptik-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  public showHamburger$: Observable<boolean>;
  constructor(breakpointObserver: BreakpointObserver) {
    this.showHamburger$ = breakpointObserver.observe(['(max-width: 400px)']).pipe(map((result) => result.matches));
  }

  public onGoToAppClick(): void {
    window.open(APP_URL, '_blank');
  }
}
