<header>
  <div *ngIf="(showHamburger$ | async) === true; else defaultMenu">
    <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/']" fragment="features">Funktionen</button>
      <button mat-menu-item [routerLink]="['/']" fragment="pricing">Preise</button>
      <button mat-menu-item [routerLink]="['/faq']">FAQ</button>
    </mat-menu>
  </div>

  <ng-template #defaultMenu>
    <div>
      <button mat-button [routerLink]="['/']" fragment="features">Funktionen</button>
      <button mat-button [routerLink]="['/']" fragment="pricing">Preise</button>
      <button mat-button [routerLink]="['/faq']">FAQ</button>
    </div>
  </ng-template>

  <button mat-stroked-button (click)="onGoToAppClick()">
    Anmelden
  </button>
  <!-- <button mat-button [matMenuTriggerFor]="menu">DE</button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>Deutsch</button>
    <button mat-menu-item>Englisch</button>
  </mat-menu> -->
</header>
