<section>
  <h2>Preise</h2>
  <h3 class="sub-title">
    Bei uns hast du ein Prepaid-Konto. Du startest mit 20 CHF. Monatlich wird abgerechnet. Die Anzahl Benutzer
    entscheidet welcher Betrag abgebucht wird. Solange du temptik nutzen möchtest, kannst du dieses Prepaid-Konto immer
    wieder aufladen. Solltest du temptik nicht mehr benötigen - einfach nicht mehr aufladen. Es ist keine Kündigung
    nötig, da es sich nicht um ein Abo handelt.
  </h3>

  <button mat-raised-button color="accent" (click)="onSignUpClick()">Jetzt mit 20 CHF Startgutgaben!</button>
  <div class="cards">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>Preisrechner</mat-card-title>
        <mat-card-subtitle>Profitiere vom Startup- oder Mengenrabatt</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <h6>ab {{ defaultPrice }} CHF</h6>
        <p class="description">
          Wir haben dynamische Preise. Du zahlst nach Anzahl Benutzer. Gib unten die Anzahl Mitarbeiter ein, die mit
          temptik arbeiten werden, um deinen Preis zu sehen.
        </p>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Anzahl Benutzer</mat-label>
          <input matInput type="number" (input)="onUsersInputChange($any($event.target).value)" #countInput />
        </mat-form-field>
        <div *ngIf="countInput.value && calculatedPrice$ | async as calculatedPrice">
          <p>Dein Preis</p>
          <h5>{{ calculatedPrice.toFixed(2) }} CHF</h5>
          <p class="discount" *ngIf="discountType === 'volume'">
            Mengenrabatt <b>{{ discount }}%</b> du sparst monatlich <b>{{ saving.toFixed(2) }} CHF</b>
          </p>
          <p class="discount" *ngIf="discountType === 'startup'">
            Spezialangebot für kleine Startups <b>{{ discount }}%</b> du sparst monatlich
            <b>{{ saving.toFixed(2) }} CHF</b>
          </p>
          <p class="promo" *ngIf="hasPromo">
            <b class="warn">Promo Aktion!</b> Bei jeder Kontoaufladung bis zum 30. April 2022 erhältst du 20% mehr
            Guthaben!
          </p>

          <button mat-raised-button color="accent" (click)="onSignUpClick()">Jetzt loslegen</button>
        </div>
      </mat-card-content>
      <mat-card-footer class="footer mat-small"> Preis inkl. MwSt, pro Monat und Benutzer </mat-card-footer>
    </mat-card>
  </div>
</section>
