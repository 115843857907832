import { DataLayer, GtagFn } from 'ngx-google-analytics';

/**
 * Check if there is some global function called gtag on Window object, or create an empty function to doesn't brake codes...
 */
export function getGtagTemptikFn(window: Window, dataLayer: DataLayer): GtagFn {
  return window
    ? (window['gtag'] =
        window['gtag'] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          const args = arguments as never;
          if (args[0] === 'config') {
            let configParams = args[2];
            if (!configParams) {
              [].push.call(args, {});
              configParams = args[2];
            }
            if (!Object.getOwnPropertyNames(configParams).includes('transport_url')) {
              Object.assign(configParams, { transport_url: 'https://gtm.temptik.com' });
            }
          }

          dataLayer.push(args);
        })
    : null;
}
