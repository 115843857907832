<section>
  <div class="headline">
    <div class="tt-logo">
      <img class="tt-icon" src="assets/tt-icon.svg" alt="temptik logo" />
      <h1>temptik</h1>
    </div>
    <h3>
      Mobile Zeit- und Spesenerfassung, Controlling und eine Vielzahl von Auswertungen, das alles ganz einfach mit der
      temptik App.
    </h3>

    <button mat-raised-button color="primary" (click)="onSignUpClick()" class="call-to-action">
      Jetzt kostenlos testen
    </button>
  </div>

  <img class="mockup" src="assets/mockup-phone.png" alt="Smartphone welches gerade die temptik app geöffnet hat." />
</section>
