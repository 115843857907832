<div class="illustration">
  <ng-content select="[illustration]"></ng-content>
</div>

<ng-content select="[header]"></ng-content>
<div class="description">
  <ng-content select="[description]"></ng-content>
</div>
<div class="mat-caption hint">
  <ng-content select="[hint]"></ng-content>
</div>
