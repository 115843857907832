import { inject, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { LayoutModule } from '@angular/cdk/layout';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
  NGX_DATA_LAYER,
  NGX_GTAG_FN,
  NGX_WINDOW,
} from 'ngx-google-analytics';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './start/home/home.component';
import { FeaturesComponent } from './start/features/features.component';
import { FeatureCardComponent } from './start/features/feature-card/feature-card.component';
import { PricingComponent } from './start/pricing/pricing.component';
import { FooterComponent } from './footer/footer.component';
import { SolutionComponent } from './start/solution/solution.component';
import { AppRoutingModule } from './app-routing.module';
import { StartComponent } from './start/start.component';
import { environment } from '../environments/environment';
import { getGtagTemptikFn } from './gtag';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FeaturesComponent,
    FeatureCardComponent,
    PricingComponent,
    FooterComponent,
    SolutionComponent,
    StartComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsCode),
    NgxGoogleAnalyticsRouterModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    LayoutModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: NGX_GTAG_FN,
      useFactory: () => getGtagTemptikFn(inject(NGX_WINDOW), inject(NGX_DATA_LAYER)),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
