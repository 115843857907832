import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SIGN_UP_URL } from '../../app.models';

@Component({
  selector: 'temptik-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
  constructor(private gaService: GoogleAnalyticsService) {}
  public onSignUpClick(): void {
    window.open(SIGN_UP_URL, '_blank');
    this.gaService.event('free_test_click', 'home', 'Free test click');
  }
}
